import { LAYOUT } from '@admin/router/constant'

// 盘点
const stocktakingRouter = {
  path: '/stocktaking',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/wms/stocktaking/tasks',
      name: 'wmsStocktakingTasks',
      component: () => import('@admin/views/wms/stocktaking/task/index.vue'),
      meta: { title: '盘点任务' },
    },
    {
      path: '/wms/stocktaking/records',
      name: 'wmsStocktakingRecords',
      component: () => import('@admin/views/wms/stocktaking/record/index.vue'),
      meta: { title: '盘点记录' },
    },
  ],
}

export default [stocktakingRouter]
