import { LAYOUT } from '@admin/router/constant'

// plm基础配置
const plmBasicConfigurationRouter = {
  path: '/plmBasicConfiguration',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/plm-basic-configuration/unit',
      name: 'Unit',
      component: () => import('@admin/views/plm/plmBasicConfiguration/unit/index.vue'),
      meta: { helpUrl: '', title: '单位' },
    },
    {
      path: '/plm-basic-configuration/color-configuration',
      name: 'plmBasicConfiguration',
      component: () =>
        import('@admin/views/plm/plmBasicConfiguration/colorConfiguration/index.vue'),
      meta: { helpUrl: '', title: '深衣颜色' },
    },
  ],
}

export default plmBasicConfigurationRouter
