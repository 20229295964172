import { LAYOUT } from '@admin/router/constant'

// cos
const CosConfigurationRouter = {
  path: '/cos',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/cos/configuration/channel-store',
      name: 'ShopSettings',
      component: () => import('@admin/views/cos/operations/shopSettings/index.vue'),
      meta: {
        title: '店铺配置',
      },
    },
    {
      path: '/cos/operations/auto-loading',
      name: 'OperationsAutoLoading',
      component: () => import('@admin/views/cos/operations/autoLoading/index.vue'),
      meta: {
        title: '关闭自动上下架',
      },
    },
    {
      path: '/cos/operations/currency',
      name: 'Currency',
      component: () => import('@admin/views/cos/operations/currency/index.vue'),
      meta: {
        title: '货币管理',
      },
    },
  ],
}

export default CosConfigurationRouter
