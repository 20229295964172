import { LAYOUT } from '@admin/router/constant'

// 营销系统路由
const operationRouter = {
  path: '/crmSystem',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/collection/productListSort',
      name: '',
      component: () => import('@admin/views/crm/collection/productListSort/index.vue'),
      meta: { helpUrl: '', title: 'Collection' },
    },
    {
      path: '/app/feedback',
      name: 'Feedback',
      component: () => import('@admin/views/crm/operate/appConfig/feedback/_index.vue'),
      meta: { helpUrl: '', title: 'Feedback' },
    },
    {
      path: '/hotkey',
      name: 'HotKey',
      component: () => import('@admin/views/crm/operate/appConfig/hotkey/index.vue'),
      meta: { helpUrl: '', title: '搜索配置' },
    },
    {
      path: '/reviews',
      name: '',
      component: () => import('@admin/views/crm/reviews/_index.vue'),
      meta: { helpUrl: '', title: '评论管理' },
    },
    {
      path: '/search',
      name: '',
      component: () => import('@admin/views/crm/search/index.vue'),
      meta: { helpUrl: '', title: '搜索权重' },
    },
    {
      path: '/collection/productListSort/view',
      name: '',
      component: () => import('@admin/views/crm/collection/productListSort/view.vue'),
      meta: { helpUrl: '', title: 'Collection查看' },
    },
    {
      path: '/collection/productListSort/edit',
      name: '',
      component: () => import('@admin/views/crm/collection/productListSort/edit.vue'),
      meta: { helpUrl: '', title: 'Collection编辑' },
    },
    {
      path: '/collection/module',
      name: '',
      component: () => import('@admin/views/crm/collection/productListSort/module.vue'),
      meta: { helpUrl: '', title: 'Collection模块' },
    },
    {
      path: '/app/startup-screen',
      name: 'startUpScreen',
      component: () => import('@admin/views/crm/operate/appConfig/startupScreen/index.vue'),
      meta: { helpUrl: '', title: '启屏页' },
    },
    {
      path: '/app/app-push',
      name: 'appPush',
      component: () => import('@admin/views/crm/operate/appConfig/appPush/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/PIzBwGt9NiDNxtkL6fZcKqJxnlf?from=from_copylink',
        title: 'PUSH',
      },
    },
    {
      path: '/app/secen-push',
      name: '',
      component: () => import('@admin/views/crm/operate/appConfig/scenePush/index.vue'),
      meta: { helpUrl: '', title: 'SCENE' },
    },
    {
      path: '/app/tabbar-icon',
      name: '',
      component: () => import('@admin/views/crm/operate/appConfig/tabbarIcon/index.vue'),
      meta: { helpUrl: '', title: 'TABBAR' },
    },
    {
      path: '/app/tabbar-icon/edit',
      name: '',
      component: () => import('@admin/views/crm/operate/appConfig/tabbarIcon/edit.vue'),
      meta: { helpUrl: '', title: 'TABBAR修改' },
    },
    {
      path: '/app/home-theme',
      name: '',
      component: () => import('@admin/views/crm/operate/appConfig/homeTheme/_index.vue'),
      meta: { helpUrl: '', title: '首页themeId配置' },
    },
    {
      path: '/app/guessYouLike',
      name: 'guessYouLike',
      component: () => import('@admin/views/crm/operate/appConfig/guessYouLike/_index.vue'),
      meta: { helpUrl: '', title: '猜你喜欢' },
    },
    {
      path: '/app/category-menu',
      name: 'AppCategoryMenu',
      component: () => import('@admin/views/crm/operate/appConfig/category-menu/_index.vue'),
      meta: { helpUrl: '', title: 'APP_分类菜单' },
    },
    // 下线不用了
    // {
    //   path: '/app/count-down',
    //   name: 'appCountDown',
    //   component: () => import('@admin/views/crm/operate/appConfig/countDown/index.vue'),
    //   meta: { helpUrl: '', title: '倒计时活动' },
    // },
    {
      path: '/nominate/utm/fb',
      name: null,
      component: () => import('@admin/views/crm/nominate/adList/adList-fb.vue'),
      meta: { helpUrl: '', title: 'FB投放' },
    },
    {
      path: '/nominate/utm/tt',
      name: null,
      component: () => import('@admin/views/crm/nominate/adList/adList-tt.vue'),
      meta: { helpUrl: '', title: 'TT投放' },
    },
    {
      path: '/nominate/utm/push',
      name: '',
      component: () => import('@admin/views/crm/nominate/adPutting/push/index.vue'),
      meta: { helpUrl: '', title: 'PUSH投放' },
    },
    {
      path: '/nominate/utm/edm',
      name: '',
      component: () => import('@admin/views/crm/nominate/adPutting/edm/index.vue'),
      meta: { helpUrl: '', title: 'EDM投放' },
    },
    {
      path: '/nominate/utm/list',
      name: '',
      component: () => import('@admin/views/crm/nominate/adList/index.vue'),
      meta: { helpUrl: '', title: '广告投放列表' },
    },
    {
      path: '/nominate/ads-other-new',
      name: 'ads',
      component: () => import('@admin/views/crm/nominate/ads-other-new/index.vue'),
      meta: { helpUrl: '', title: '其他渠道投放' },
    },
    {
      path: '/nominate/config',
      name: null,
      component: () => import('@admin/views/crm/nominate/launchSetting/index.vue'),
      meta: { helpUrl: '', title: '投放参数配置' },
    },
    {
      path: '/nominate/fb-product-achrive',
      name: null,
      component: () => import('@admin/views/crm/nominate/ArchiveFBProduct/index.vue'),
      meta: { helpUrl: '', title: 'FB商品归档' },
    },
    {
      path: '/crowd/push',
      name: '',
      component: () => import('@admin/views/crm/user-management/crowd/push/index.vue'),
      meta: { helpUrl: '', title: '人群应用' },
    },
    {
      path: '/user-management/user-list',
      name: 'userCenterUserList',
      component: () => import('@admin/views/crm/user-management/user-list/index/index.vue'),
      meta: { helpUrl: '', title: '用户列表' },
    },
    {
      path: '/user-management/user-360',
      name: 'userCenterUser360',
      component: () => import('@admin/views/crm/user-management/user-list/index/detail.vue'),
      meta: { helpUrl: '', title: '用户360' },
    },
    {
      path: '/permission/user-field-list',
      name: '',
      component: () => import('@admin/views/system/user-field/index.vue'),
      meta: { helpUrl: '', title: '用户表字段配置' },
    },
    {
      path: '/logistics/logistics-remind-new',
      name: '',
      component: () => import('@admin/views/crm/logistics/logistics-remind/index.vue'),
      meta: { helpUrl: '', title: '物流页面提醒' },
    },
    {
      path: '/logistics/return-reson',
      name: '',
      component: () => import('@admin/views/crm/logistics/returnReason/index.vue'),
      meta: { helpUrl: '', title: '退货理由配置' },
    },
    {
      path: '/logistics/return-mail',
      name: '',
      component: () => import('@admin/views/crm/logistics/returnMail/index.vue'),
      meta: { helpUrl: '', title: '退货邮件模板配置' },
    },
    {
      path: '/operateCent/set/return-mail/edit',
      name: '',
      component: () => import('@admin/views/crm/logistics/returnMail/edit.vue'),
      meta: { helpUrl: '', title: '退货邮件模板配置-编辑' },
    },
    {
      path: '/operateCent/filterConfig',
      name: '',
      component: () => import('@admin/views/crm/operate/filterConfig/index.vue'),
      meta: { helpUrl: '', title: 'filter配置' },
    },
    {
      path: '/logistics/logistics-time',
      name: 'logisticsTime',
      component: () => import('@admin/views/crm/logisticsTime/_index.vue'),
      meta: { helpUrl: '', title: '物流时长' },
    },
    {
      path: '/logistics/logistics-time-edit',
      name: 'logisticsTimeEdit',
      component: () => import('@admin/views/crm/logisticsTime/edit.vue'),
      meta: { helpUrl: '', title: '物流时长详情' },
    },
  ],
}
export default operationRouter
