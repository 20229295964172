import { LAYOUT } from '@admin/router/constant'

// 数据中心路由
const dataRouter = {
  path: '/dataCenterSystem',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/Dashboard/Dashboard',
      name: 'Dashboard',
      component: () => import('@admin/views/data/dashboard/dashboard/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/docx/FPwvdLKbUocGNQxDmLKciEWnnAg',
        title: '经营日报',
      },
    },
    {
      path: '/Dashboard/Target',
      name: 'target',
      component: () => import('@admin/views/data/dashboard/target/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/docx/FPwvdLKbUocGNQxDmLKciEWnnAg',
        title: '经营指标',
      },
    },
    {
      path: '/Dashboard/operate-report',
      name: 'operateReport',
      component: () => import('@admin/views/data/dashboard/operateReport/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/UH4uwDRSaiFyEhkM7tmckipznZb',
        title: '经营报表',
      },
    },
    {
      path: '/Dashboard/market-list',
      name: 'marketList',
      component: () => import('@admin/views/data/dashboard/market/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/docx/FPwvdLKbUocGNQxDmLKciEWnnAg',
        title: '营销报表',
      },
    },
    {
      path: '/reports/customer-old-new',
      name: 'customerOldNew',
      component: () => import('@admin/views/data/reports/repeatPuchases/customerOldNew.vue'),
      meta: { helpUrl: '', title: '新老客报表' },
    },
    {
      path: '/goods/goods-list-ranking',
      name: '_goodsListNew',
      component: () => import('@admin/views/cos/goodsManagement/goodsList/_index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/MCK9wQr1di34i2kpjuCc7mCPnlf',
        title: '商品排行',
      },
    },
    {
      path: '/goods/goods-plate',
      name: 'goods-plate-new',
      component: () => import('@admin/views/cos/goodsManagement/goodsPlate/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/Hpo1wthA4iwwKxkKzVYcmPRinQb',
        title: '商品看板',
      },
    },
    {
      path: '/reports/repeat-purchases-month',
      name: 'repeatPurchasesOrderMonth',
      component: () =>
        import('@admin/views/data/reports/repeatPuchases/repeatPurchasesOrderMonth.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/SLkXwo12oiZ0ankfKCdcH0uLnnh',
        title: '月度复购',
      },
    },
    {
      path: '/reports/repeat-purchases-month-terminal',
      name: 'repeatPurchasesOrderMonthTerminal',
      component: () =>
        import('@admin/views/data/reports/repeatPuchases/repeatPurchasesOrderMonthTerminal.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/SLkXwo12oiZ0ankfKCdcH0uLnnh',
        title: '月度复购-分端',
      },
    },
    {
      path: '/reports/repeat-purchases-order-30day-all',
      name: 'repeatPurchasesOrder30DayAll',
      component: () =>
        import('@admin/views/data/reports/repeatPuchases/repeatPurchasesOrder30DayAll.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/SLkXwo12oiZ0ankfKCdcH0uLnnh',
        title: '下单30天复购',
      },
    },
    {
      path: '/reports/repeat-purchases',
      name: 'repeatPurchases',
      component: () => import('@admin/views/data/reports/repeatPuchases/repeatPurchases.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/SLkXwo12oiZ0ankfKCdcH0uLnnh',
        title: '下单45天复购',
      },
    },
    {
      path: '/reports/repeat-purchases-order-sign-30day',
      name: 'repeatPurchasesOrderSign30Day',
      component: () =>
        import('@admin/views/data/reports/repeatPuchases/repeatPurchasesOrderSign30Day.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/SLkXwo12oiZ0ankfKCdcH0uLnnh',
        title: '签收30天复购',
      },
    },
    {
      path: '/reports/repeat-purchases-order-sign-30day-terminal',
      name: 'repeatPurchasesOrderSign30DayTerminal',
      component: () =>
        import(
          '@admin/views/data/reports/repeatPuchases/repeatPurchasesOrderSign30DayTerminal.vue'
        ),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/SLkXwo12oiZ0ankfKCdcH0uLnnh',
        title: '签收30天复购-分端',
      },
    },
    {
      path: '/reports/repeat-purchases-order-sign-7day',
      name: 'repeatPurchasesOrderSign7Day',
      component: () =>
        import('@admin/views/data/reports/repeatPuchases/repeatPurchasesOrderSign7Day.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/SLkXwo12oiZ0ankfKCdcH0uLnnh',
        title: '周度复购-签收30天',
      },
    },
    {
      path: '/refund/refund-percent-total',
      name: 'refundRateTotal',
      component: () =>
        import('@admin/views/data/biData/refundRateReport/refundRateTotal/index.vue'),
      meta: { helpUrl: '', title: '总退款率' },
    },
    {
      path: '/refund/refund-percent-country',
      name: 'refundRateCountry',
      component: () =>
        import('@admin/views/data/biData/refundRateReport/refundRateCountry/index.vue'),
      meta: { helpUrl: '', title: '国家维度退款率' },
    },
    {
      path: '/refund/refund-percent-country-province',
      name: 'refundRateRegion',
      component: () =>
        import('@admin/views/data/biData/refundRateReport/refundRateRegion/index.vue'),
      meta: { helpUrl: '', title: '地区维度退款率' },
    },
    {
      path: '/nominate/hotsticker',
      name: '',
      component: () => import('@admin/views/data/hotsticker/index.vue'),
      meta: { helpUrl: '', title: '红人帖子🎩' },
    },
    {
      path: '/logistics/signer',
      name: 'signer',
      component: () => import('@admin/views/crm/logistics/signer/index.vue'),
      meta: { helpUrl: '', title: '签收跟踪表' },
    },
    {
      path: '/logistics/new-issue-rate',
      name: '',
      component: () => import('@admin/views/crm/logistics/issueRate/index.vue'),
      meta: { helpUrl: '', title: '订单发货率' },
    },
    {
      path: '/logistics/circulation-day-order',
      name: '',
      component: () =>
        import('@admin/views/data/biData/performanceCenter/circulationDayOrder/index.vue'),
      meta: { helpUrl: '', title: '流转日订单' },
    },
    {
      path: '/logistics/dynamic-order',
      name: '',
      component: () => import('@admin/views/data/biData/performanceCenter/dynamicOrder/index.vue'),
      meta: { helpUrl: '', title: '动态未发订单' },
    },
    {
      path: '/daily/daily-report',
      name: 'dailyReport',
      component: () => import('@admin/views/data/daily/daily-report.vue'),
      meta: { helpUrl: '', title: '自定义日报' },
    },
    {
      path: '/daily/daily-report-see',
      name: 'daily-report-see',
      component: () => import('@admin/views/data/daily/daily-report-see.vue'),
      meta: { helpUrl: '', title: '自定义日报查看' },
    },
    {
      path: '/daily/daily-report-edit',
      name: 'daily-report-edit',
      component: () => import('@admin/views/data/daily/daily-report-edit.vue'),
      meta: { helpUrl: '', title: '自定义日报编辑' },
    },
    {
      path: '/bi-tools/push-tools',
      name: 'pushTools',
      component: () => import('@admin/views/data/biTools/pushTools/index.vue'),
      meta: { helpUrl: '', title: '推送工具' },
    },
    {
      path: '/bi-tools/push-tools-edit',
      name: 'pushToolsEdit',
      component: () => import('@admin/views/data/biTools/pushTools/edit.vue'),
      meta: { helpUrl: '', title: '推送工具编辑' },
    },
    {
      path: '/bi-tools/push-robot-edit',
      name: 'pushRobotEdit',
      component: () => import('@admin/views/data/biTools/pushTools/robotEdit.vue'),
      meta: { helpUrl: '', title: '机器人编辑' },
    },
    {
      path: '/permission/field-list',
      name: 'fieldList',
      component: () => import('@admin/views/system/fieldList/index.vue'),
      meta: { helpUrl: '', title: '商品数据配置' },
    },
  ],
}

export default dataRouter
