import { LAYOUT } from '@admin/router/constant'

// app 会员
const AppMemberRouter = {
  path: '/app',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/app/member-list',
      name: 'MemberList',
      component: () => import('@admin/views/app/member/index/_index.vue'),
      meta: {
        title: '会员列表',
      },
    },
    {
      path: '/app/member-detail',
      name: 'MemberDetail',
      component: () => import('@admin/views/app/member/index/detail.vue'),
      meta: {
        title: '会员详情',
      },
    },
    {
      path: '/app/member-level',
      name: 'MemberLevelList',
      component: () => import('@admin/views/app/member/level/_index.vue'),
      meta: {
        title: '会员等级',
      },
    },
    {
      path: '/app/member-level-detail',
      name: 'MemberLevelDetail',
      component: () => import('@admin/views/app/member/level/detail.vue'),
      meta: {
        title: '会员等级详情',
      },
    },
    {
      path: '/app/member-task',
      name: 'AppMemberTask',
      component: () => import('@admin/views/app/member/task/_index.vue'),
      meta: {
        title: '会员任务',
        helpUrl: '',
      },
    },
    {
      path: '/app/member-task-detail',
      name: 'AppMemberTaskDateil',
      component: () => import('@admin/views/app/member/task/detail.vue'),
      meta: {
        title: '会员任务详情',
        helpUrl: '',
      },
    },
    {
      path: '/app/member-exchange',
      name: 'AppMemberExchange',
      component: () => import('@admin/views/app/member/exchange/_index.vue'),
      meta: {
        title: '积分兑换',
        helpUrl: 'https://bloomchic.feishu.cn/docx/FPwvdLKbUocGNQxDmLKciEWnnAg',
      },
    },
    {
      path: '/app/member-exchange-detail',
      name: 'AppMemberExchangeDetail',
      component: () => import('@admin/views/app/member/exchange/detail.vue'),
      meta: {
        title: '积分兑换详情',
      },
    },
  ],
}

export default AppMemberRouter
