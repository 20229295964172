import type { RouteLocationNormalized, RouteLocationRaw, Router } from 'vue-router'

import { toRaw, unref } from 'vue'
import { defineStore } from 'pinia'
import { store } from '@admin/store'
import { useGo, useRedo } from 'framework/hooks/web/usePage'
import { Persistent } from 'framework/utils/cache/persistent'

import { PageEnum } from '@admin/enums/pageEnum'
import { getRawRoute } from 'framework/utils'
import { MULTIPLE_TABS_KEY } from 'framework/enums/cacheEnum'

import projectSetting from '@admin/settings/projectSetting'
import dayjs from 'dayjs'
import bus from 'framework/utils/bus'

export interface MultipleTabState {
  cacheTabList: Set<string>
  tabList: RouteLocationNormalized[]
  affixEndIndex: number
}

const getToTarget = (tabItem: RouteLocationNormalized) => {
  const { params, path, query } = tabItem
  return {
    params: params || {},
    path,
    query: query || {},
  }
}

const cacheTab = projectSetting.multiTabsSetting.cache

export const useMultipleTabStore = defineStore({
  id: 'app-multiple-tab',
  state: (): MultipleTabState => ({
    // Tabs that need to be cached
    cacheTabList: new Set(),
    // multiple tab list
    tabList: cacheTab ? Persistent.getLocal(MULTIPLE_TABS_KEY) || [] : [],
    // Index of the last moved tab
    affixEndIndex: 0,
  }),
  getters: {
    getCachedTabList(): string[] {
      return Array.from(this.cacheTabList)
    },
    getCurrentTab(): any {
      return (currentRoute) => {
        return this.tabList.find((item) => item.fullPath === currentRoute.fullPath)
      }
    },
  },
  actions: {
    clearCacheTabs(): void {
      this.cacheTabList = new Set()
    },
    resetState(): void {
      this.tabList = []
      this.cacheTabList = new Set()
    },
    goToPage(router: Router) {
      const go = useGo(router)
      const len = this.tabList.length
      const { path } = unref(router.currentRoute)

      let toPath: PageEnum | string = PageEnum.BASE_HOME

      if (len > 0) {
        const page = this.tabList[len - 1]
        const p = page.fullPath || page.path
        if (p) {
          toPath = p
        }
      }
      // Jump to the current page and report an error
      path !== toPath && go(toPath as PageEnum, true)
    },

    // Close according to key
    async closeTabByKey(key: string, router: Router, redirect?: string, refresh = false) {
      const index = this.tabList.findIndex(
        (item) =>
          (decodeURIComponent(item.fullPath) || decodeURIComponent(item.path)) ===
          decodeURIComponent(key),
      )

      if (index !== -1) {
        const { currentRoute, replace } = router
        const route = unref(currentRoute)

        bus.emit(`delete_${route.fullPath}`)
        this.delTabCache(this.tabList[index])
        await this.closeTab(this.tabList[index], router)
        if (redirect) {
          router.push(redirect) // 重置跳转
          if (refresh) {
            setTimeout(async () => {
              await this.refreshPage(router)
            }, 100)
          }
          return
        }
        // 检查当前路由是否存在于tabList中
        const isActivated = this.tabList.findIndex((item) => {
          return (
            decodeURIComponent(item.fullPath) === decodeURIComponent(currentRoute.value.fullPath)
          )
        })
        // 如果当前路由不存在于TabList中，尝试切换到其它路由
        if (isActivated === -1) {
          let pageIndex
          if (index > 0) {
            pageIndex = index - 1
          } else if (index < this.tabList.length - 1) {
            pageIndex = index + 1
          } else {
            pageIndex = -1
          }
          if (pageIndex >= 0) {
            const page = this.tabList[index - 1]
            const toTarget = getToTarget(page)
            await replace(toTarget)
          }
        }
      }
    },

    // Sort the tabs
    async sortTabs(oldIndex: number, newIndex: number) {
      const currentTab = this.tabList[oldIndex]
      this.tabList.splice(oldIndex, 1)
      this.tabList.splice(newIndex, 0, currentTab)
    },

    /**
     * Close left tabs
     */
    async closeLeftTabs(route: RouteLocationNormalized, router: Router) {
      const { currentRoute } = router
      const index = this.tabList.findIndex(
        (item) => item.fullPath === (route.fullPath || currentRoute.value.fullPath),
      )
      if (index > 0) {
        this.tabList.slice(0, index).forEach((v) => {
          this.closeTab(v, router)
          this.delTabCache(v)
        })
      }
    },

    /**
     * Close right tabs
     */
    async closeRightTabs(route: RouteLocationNormalized, router: Router) {
      const { currentRoute } = router
      const index = this.tabList.findIndex(
        (item) => item.fullPath === (route.fullPath || currentRoute.value.fullPath),
      )
      if (index >= 0 && index < this.tabList.length - 1) {
        this.tabList.slice(index + 1).forEach((v) => {
          this.closeTab(v, router)
          this.delTabCache(v)
        })
      }
    },

    /**
     * Close other tabs
     */
    async closeOtherTabs(route: RouteLocationNormalized, router: Router) {
      const { currentRoute } = router
      this.tabList
        .filter((v) => v.fullPath !== (route.fullPath || currentRoute.value.fullPath))
        .forEach((v) => {
          this.closeTab(v, router)
          this.delTabCache(v)
        })
    },

    /**
     * Refresh tabs
     */
    async refreshPage(router: Router) {
      const { currentRoute } = router
      const route = unref(currentRoute)
      const name = route.name
      if (name === 'iframe') {
        const findData = this.tabList.find(
          (item) =>
            decodeURIComponent(item.fullPath) === decodeURIComponent(currentRoute.value.fullPath),
        )
        return bus.emit(`${findData?.meta?.iframeId}`)
      } else {
        const findTab = this.getCachedTabList.find((item) => item === name)
        if (findTab) {
          this.cacheTabList.delete(findTab)
        }
      }

      bus.emit(`refresh_${route.fullPath}`)
      const redo = useRedo(router)
      await redo()
    },

    /**
     * Set tab's title
     */
    async setTabTitle(title: string, route: RouteLocationNormalized) {
      const findTab = this.tabList.find((item) => item === route)
      if (findTab) {
        findTab.meta.title = title
      }
    },

    async addTab(route: RouteLocationNormalized) {
      const { path, fullPath, params, query, name } = getRawRoute(route)

      // 404  The page does not need to add a tab
      if ([PageEnum.ERROR_PAGE, PageEnum.BASE_LOGIN, '/'].includes(path) || name === 'Login') {
        return
      }
      // 查找是否已经存在、存在修改openTime
      let updateIndex = -1
      const tabHasExits = this.tabList.some((tab, index) => {
        updateIndex = index
        return decodeURIComponent(tab.fullPath || tab.path) === decodeURIComponent(fullPath || path)
      })
      if (tabHasExits) {
        const curTab = toRaw(this.tabList)[updateIndex]
        if (!curTab) {
          return
        }
        curTab.params = params || curTab.params
        curTab.query = query || curTab.query
        curTab.fullPath = fullPath || curTab.fullPath
        curTab.meta.openTime = dayjs().unix()
        this.tabList.splice(updateIndex, 1, curTab)
      } else {
        // Add tab
        this.tabList.push(route)
      }
      cacheTab && Persistent.setLocal(MULTIPLE_TABS_KEY, this.tabList)
    },

    // openPrevious: 关闭tab——默认打开上一个
    async closeTab(tab: RouteLocationNormalized, router: Router, openPrevious = true) {
      const close = (route: RouteLocationNormalized) => {
        const { fullPath, meta: { affix } = {} } = route
        if (affix) {
          return
        }
        const index = this.tabList.findIndex(
          (item) => decodeURIComponent(item.fullPath) === decodeURIComponent(fullPath),
        )
        index !== -1 && this.tabList.splice(index, 1)
      }

      const { currentRoute, replace } = router

      const { fullPath } = unref(currentRoute)
      if (decodeURIComponent(fullPath) !== decodeURIComponent(tab.fullPath)) {
        // Closed is not the activation tab
        close(tab)
        return
      }
      // Closed is activated atb
      let toTarget: RouteLocationRaw = {}

      const index = this.tabList.findIndex(
        (item) => decodeURIComponent(item.fullPath) === decodeURIComponent(fullPath),
      )

      // If the current is the leftmost tab
      if (index === 0) {
        // There is only one tab, then jump to the homepage, otherwise jump to the right tab
        //  Jump to the right tab
        const page = this.tabList[index + 1]
        toTarget = getToTarget(page)
      } else {
        // Close the current tab
        const page = this.tabList[index - 1]
        toTarget = getToTarget(page)
      }
      close(currentRoute.value)
      if (openPrevious) {
        await replace(toTarget)
      }
    },

    /**
     * 新增tab缓存操作
     */
    addTabCache(route) {
      if (route.name && route.name !== 'iframe') {
        this.cacheTabList.add(route.name)
      }
    },
    /**
     * 删除tab缓存操作
     */
    delTabCache(route) {
      if ((!route || !route.name) && route.name !== 'iframe') return
      if (this.cacheTabList.has(route.name)) {
        this.cacheTabList.delete(route.name)
      }
    },
    tabTopping() {
      this.tabList = this.tabList.sort(
        (a, b) => Number(b.meta.affix || 0) - Number(a.meta.affix || 0),
      )
    },
    setAffixEndIndex(val) {
      this.affixEndIndex = val
    },
  },
})

// Need to be used outside the setup
export function useMultipleTabWithOutStore() {
  return useMultipleTabStore(store)
}
